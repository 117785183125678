class FlashesExtension {
    initialize(naja)
    {
        window.addEventListener('DOMContentLoaded', this.closeAlerts.bind(this));
        naja.addEventListener('complete', this.closeAlerts.bind(this));
    }

    closeAlerts()
    {
        const alerts = document.querySelectorAll('.alert');

        alerts.forEach(
            (alert) => {
                setTimeout(
                () => {
                        const closeButton = alert.querySelector('.btn-close');
                        closeButton.click();
                }, 6000
            );
            }
        );
    }
}

export default FlashesExtension;
