import naja from "naja";
import Sortable from 'sortablejs';
import 'slick-carousel';

document.addEventListener('DOMContentLoaded', () => {
    const initPlan = $('.initPlan');
    if (!initPlan.length) {
        return;
    }

    const plan = new Plan();
    plan.init();

    naja.addEventListener('complete', () => {
        plan.init();
    });
});

class Plan {
    init() {
        this.initSortableDay('users');
        this.initSortableDay('activities');
        this.initYearChange();
        this.initWeekSlick();
        this.initDoubleClick();
        this.initAllDaysCheckbox();
        this.initSortableTeams();
        this.changeUrl();
    }

    initSortableDay(selector) {
        const elDays = document.querySelectorAll('.day');
        elDays.forEach((el, index) => {
            this.initSortable(el.querySelector('.day-' + selector), el.querySelectorAll('.team-' + selector), 'group-' + selector + '-' + index, selector);
        });
    }

    initSortable(el, elList, group, selector) {
        if (el && elList) {
            new Sortable(el, {
                group: group,
                draggable: '.btn',
                onMove(evt) {
                    if (evt.from === evt.to) {
                        return false
                    }
                },
                onEnd: function (/**Event*/ evt) {
                    if (evt.from === evt.to) {
                        return;
                    }
                    const itemEl = evt.item;

                    let url = evt.to.dataset.add.replace('=-1', '=' + itemEl.dataset.id);
                    if (typeof itemEl.dataset.type !== "undefined") {
                        url = url.replace('=-2', '=' + itemEl.dataset.type);
                    }

                    if (selector === 'activities'){
                        url = url.replace('=-2', '=' + evt.newDraggableIndex);
                    }

                    naja.makeRequest('GET', url);
                },
            });

            elList.forEach((el) => {
                new Sortable(el, {
                    group: group,
                    draggable: '.btn',
                    onMove(evt) {
                        if (evt.from === evt.to && selector === 'users') {
                            return false
                        }
                    },
                    onEnd: function (/**Event*/ evt) {
                        if (evt.from === evt.to && selector === 'users') {
                            return;
                        }
                        const itemEl = evt.item;

                        if (evt.from.classList.contains('team-' + selector) && evt.to.classList.contains('team-' + selector)) {
                            let url = '';
                            if (evt.from === evt.to) {
                                 url = itemEl.dataset.move.replace('=-1', '=' + evt.newDraggableIndex);
                            }else{
                                url = evt.to.dataset.move.replace('=-1', '=' + itemEl.dataset.id).replace('=-2', '=' + evt.from.dataset.id);
                                if (typeof itemEl.dataset.type !== "undefined") {
                                    url = url.replace('=-3', '=' + itemEl.dataset.type);
                                }

                                if (selector === 'activities'){
                                    url = url.replace('=-3', '=' + evt.newDraggableIndex);
                                }
                            }

                            naja.makeRequest('GET', url);
                        } else {
                            let url = evt.from.dataset.remove.replace('=-1', '=' + itemEl.dataset.id);
                            if (typeof itemEl.dataset.type !== "undefined") {
                                url = url.replace('=-2', '=' + itemEl.dataset.type);
                            }

                            naja.makeRequest('GET', url);
                        }
                    },
                });
            });
        }
    }

    initSortableTeams() {
        const elDays = document.querySelectorAll('.teams');
        elDays.forEach((el, index) => {
            const sortable = new Sortable(el, {
                draggable: '.team-draggable',
                handle: '.team-handle',
                onEnd: function (/**Event*/ evt) {
                    naja.makeRequest('GET', evt.item.dataset.move.replace('=-1', '=' + evt.newIndex));
                },
            });
        });
    }

    changeUrl() {
        const url = new URL(window.location.href);
        url.searchParams.set('week', $('.week-active').data('number'));
        url.searchParams.set('year', $('#year').val());
        window.history.pushState({}, '', url);
    }

    initYearChange() {
        let self = this;
        $('#year').on('change', function () {
            naja.makeRequest('GET', $(this).data('change-link').replace('-year-', $(this).val()));
            self.changeUrl();
        });
    }

    initWeekSlick() {
        let self = this;
        const slider = $('.weeks-slider');
        if (!slider.hasClass('slick-initialized')) {

            // console.log($('.week-link').index($('.week-active')));

            const node = document.querySelector('.week-active').parentNode;

            $('.week-list')

            slider.slick({
                initialSlide: Array.prototype.indexOf.call(node.parentNode.children, node),
                slidesToShow: 9,
                slidesToScroll: 5,
                focusOnSelect: true,
                prevArrow: '<button class="btn btn-sm prev"><i class="bi bi-chevron-left"></i></button>',
                nextArrow: '<button class="btn btn-sm next"><i class="bi bi-chevron-right"></i></button>',
                // infinite: true,
                infinite: false,
                centerMode: true,
                // responsive: [
                //     {
                //         breakpoint: 1024,
                //         settings: {
                //             slidesToShow: 10,
                //             slidesToScroll: 10,
                //             prevArrow: $('.bi-chevron-double-left'),
                //             nextArrow: $('.bi-chevron-double-right'),
                //         }
                //     }
                // ]

            });

            slider.slick('slickSetOption', 'slidesToScroll', 5, true);

            slider.on('click', 'a', function () {
                slider.find('a.is-actual').removeClass('btn-danger week-active').addClass('btn-outline-danger');
                slider.find('a.is-no-actual').removeClass('btn-primary week-active').addClass('btn-outline-primary');

                let color = $(this).hasClass('is-actual') ? 'danger' : 'primary';
                $(this).removeClass('btn-outline-primary btn-outline-danger').addClass('btn-' + color + ' week-active');

                $('.fixed-selected-kt').text('KT' + $('.week-active').data('number'));

                self.changeUrl();
            });
        }
    }

    initDoubleClick() {
        $('.btn-activity').off('dblclick').on('dblclick', function () {
            const editButton = $(this).find('.action-edit-btn').get(0);
            if (editButton) {
                naja.uiHandler.clickElement(editButton);
            }
        });

        $('.col-teams .btn-user').off('dblclick').on('dblclick', function () {
            const userBtn = $(this);
            naja.makeRequest('GET', $(this).closest('.team-users').data('remove').replace('=-1', '=' + $(this).data('id')));
        });
    }

    initAllDaysCheckbox() {
        $('input[name=allDays').off('click').on('click', function () {
            const checked = $(this).prop('checked');
            $('input[name^=days]').prop('checked', checked);
        });
    }
}
