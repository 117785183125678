$.sidebarMenu = function (menu) {
    var animationSpeed = 300;

    $(menu).on(
        "click", "li a", function (e) {
            var $this = $(this);
            var checkElement = $this.next();

            if (checkElement.is(".treeview-menu") && checkElement.is(":visible")) {
                checkElement.slideUp(
                    animationSpeed, function () {
                        checkElement.removeClass("menu-open");
                    }
                );
                checkElement.parent("li").removeClass("active");
            }

            //If the menu is not visible
            else if (checkElement.is(".treeview-menu")
                && !checkElement.is(":visible")
            ) {
                //Get the parent menu
                var parent = $this.parents("ul").first();
                //Close all open menus within the parent
                var ul = parent.find("ul:visible").slideUp(animationSpeed);
                //Remove the menu-open class from the parent
                ul.removeClass("menu-open");
                //Get the parent li
                var parent_li = $this.parent("li");

                //Open the target menu and add the menu-open class
                checkElement.slideDown(
                    animationSpeed, function () {
                        //Add the class active to the parent li
                        checkElement.addClass("menu-open");
                        parent.find("li.active").removeClass("active");
                        parent_li.addClass("active");
                    }
                );
            }
            //if this isn't a link, prevent the page from being redirected
            if (checkElement.is(".treeview-menu")) {
                e.preventDefault();
            }
        }
    );
};
$.sidebarMenu($(".sidebar-menu"));

// Custom Sidebar JS
jQuery(
    function ($) {
        //toggle sidebar
        $("#toggle-sidebar").on(
            "click", function () {
                $(".page-wrapper").toggleClass("toggled");
            }
        );

        // Pin sidebar on click
        $("#pin-sidebar").on(
            "click", function () {
                if ($(".page-wrapper").hasClass("pinned")) {
                    // unpin sidebar when hovered
                    $(".page-wrapper").removeClass("pinned");
                    $("#sidebar").unbind("hover");
                } else {
                    $(".page-wrapper").addClass("pinned");
                    $("#sidebar").hover(
                        function () {
                            $(".page-wrapper").addClass("sidebar-hovered");
                        },
                        function () {
                            $(".page-wrapper").removeClass("sidebar-hovered");
                        }
                    );
                }
            }
        );

        // Pinned sidebar
        $(
            function () {
                $(".page-wrapper").hasClass("pinned");
                $("#sidebar").hover(
                    function () {
                        $(".page-wrapper").addClass("sidebar-hovered");
                    },
                    function () {
                        $(".page-wrapper").removeClass("sidebar-hovered");
                    }
                );
            }
        );

        // Toggle sidebar overlay
        $("#overlay").on(
            "click", function () {
                $(".page-wrapper").toggleClass("toggled");
            }
        );

        // Added by Srinu
        $(
            function () {
                // When the window is resized,
                $(window).resize(
                    function () {
                        // When the width and height meet your specific requirements or lower
                        if ($(window).width() <= 768) {
                            $(".page-wrapper").removeClass("pinned");
                        }
                    }
                );
                // When the window is resized,
                $(window).resize(
                    function () {
                        // When the width and height meet your specific requirements or lower
                        if ($(window).width() >= 768) {
                            $(".page-wrapper").removeClass("toggled");
                        }
                    }
                );
            }
        );
    }
);
