$(
    function () {
        $(".sidebarMenuScroll").overlayScrollbars(
            {
                scrollbars: {
                    visibility: "auto",
                    autoHide: "scroll",
                    autoHideDelay: 200,
                    dragScrolling: true,
                    clickScrolling: false,
                    touchSupport: true,
                    snapHandle: false,
                },
            }
        );
    }
);

// Scroll 290
$(
    function () {
        $(".scroll290").overlayScrollbars(
            {
                scrollbars: {
                    visibility: "auto",
                    autoHide: "scroll",
                    autoHideDelay: 200,
                    dragScrolling: true,
                    clickScrolling: false,
                    touchSupport: true,
                    snapHandle: false,
                },
            }
        );
    }
);

// Scroll 300
$(
    function () {
        $(".scroll300").overlayScrollbars(
            {
                scrollbars: {
                    visibility: "auto",
                    autoHide: "scroll",
                    autoHideDelay: 200,
                    dragScrolling: true,
                    clickScrolling: false,
                    touchSupport: true,
                    snapHandle: false,
                },
            }
        );
    }
);

// Scroll 350
$(
    function () {
        $(".scroll350").overlayScrollbars(
            {
                scrollbars: {
                    visibility: "auto",
                    autoHide: "scroll",
                    autoHideDelay: 200,
                    dragScrolling: true,
                    clickScrolling: false,
                    touchSupport: true,
                    snapHandle: false,
                },
            }
        );
    }
);
